import {
  gql,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

const GENERATE_SIGNED_URL_FOR_IMAGE = gql`
  mutation GenerateSigned($input: SignedUrlInputType!) {
    generateSignedUrl(input: $input)
  }
`;

export const uploadImageDirectly = async (file) => {
  const key = `${uuid()}.${file.name.split(".").pop()}`;
  const bucketParams = {
    key: key,
    contentType: file?.type,
  };

  try {
    const { data } = await client.mutate({
      mutation: GENERATE_SIGNED_URL_FOR_IMAGE,
      variables: {
        input: bucketParams,
      },
    });

    if (!data?.generateSignedUrl) {
      throw new Error("Failed to generate signed URL");
    }
    const response = await axios.put(data.generateSignedUrl, file, {
      headers: {
        "Content-Type": `${file.type}`,
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to generate signed URL");
    }

    const baseUrl =
      response.status === 200
        ? `${process.env.REACT_APP_AWS_BASE_URL}/${key}`
        : null;
    return baseUrl;
  } catch (error) {
    console.error("Image upload failed", error);
    throw error;
  }
};
