/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stack,
  InputAdornment,
  Avatar,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import Spinner from "components/spinner";
import useSnackbar from "hooks/common/useSnackbar";
import { useGQL } from "./hooks/useGQL";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
//date range picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";

import {
  BannerColors,
  EventType,
  isComplianceArray,
  Modality,
  PageTypeArray,
} from "store/constant";
// assets
import { eventValidationSchema } from "./eventValidationSchema";
import { EventInformation, SessionOutput } from "types/event";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";

import ValidateFileType from "common/validateFileType";
import useImageUpload from "common/handleFileUpload";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import lang from "constants/language";
import moment from "moment";
import { formatTime } from "./timeFormatter";
import { v4 as uuidv4 } from "uuid";
import validateFileSize from "common/fileSizeValidation";
import { formatDate } from "./dateFormatter";

// import { handleCancelEvent } from 'common/cancelEvent';
// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const EventDataInformation = ({ handleCancelEvent }) => {
  const { handleOpenSnackbar } = useSnackbar();
  const { GET_EVENT_INFORMATION_DETAILS, UPDATE_EVENT_INFORMATIONS } = useGQL();

  // const { handleCancelEvent } = useCancelEvent();

  const { handleFileUpload } = useImageUpload();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const eventId = params.get("eventId") as string;
  const currentDate = new Date();
  const [submitting, setSubmitting] = useState<boolean>(false);

  type fileUploadType = {
    fileName: string;
    fileType: string;
    key: string;
    file: string;
  };
  const [clickImageChange, setClickImageChange] = useState<boolean>(false);
  const [clickPdfChange, setClickPdfChange] = useState<boolean>(false);

  //uploadObject
  const [uploadImage, setUploadImage] = useState<fileUploadType>({
    fileName: "",
    fileType: "",
    key: "",
    file: "",
  });
  //uploadObject
  const [uploadPdf, setUploadPdf] = useState<fileUploadType>({
    fileName: "",
    fileType: "",
    key: "",
    file: "",
  });
  const [thumb, setThumb] = useState(false);
  const [thumbLarge, setThumbLarge] = useState(true);

  const [imageSrc, setImageSrc] = useState<string>();
  const [pdfSrc, setPdfSrc] = useState<string>("");
  const {
    error: getEventError,
    loading: getEventLoading,
    data: eventInformationData,
    refetch,
  } = GET_EVENT_INFORMATION_DETAILS(eventId);

  useEffect(() => {
    refetch();
  }, [eventId]);

  const eventInformationDetails = eventInformationData?.getEvent;
  const data = { ...eventInformationDetails };
  const uniqueId = uuidv4();
  const [handleUpdate, { data: update, loading, error }] =
    UPDATE_EVENT_INFORMATIONS();

  useEffect(() => {
    if (update?.updateEventInformation) {
      setSubmitting(false);

      handleOpenSnackbar({
        message: eventId ? lang.EVENT.UPDATE_EVENT : lang.EVENT.CREATE_EVENT,
        alertType: "success",
      });
      navigate(`/events?eventId=${update.updateEventInformation._id}`);
    }
  }, [update]);

  const hiddenImageInput: any = useRef(null);
  const hiddenPdfInput: any = useRef(null);
  const handleButtonClick = (event: any) => {
    hiddenImageInput.current.click();
  };
  const handleButtonClickPdf = (event: any) => {
    hiddenPdfInput.current.click();
  };

  const handleImageDisplay = (event: any) => {
    event.preventDefault();

    if (ValidateFileType(event, "IMAGE") && validateFileSize(event)) {
      if (event.target.files[0]) {
        setSubmitting(true);
        setImageSrc(URL.createObjectURL(event.target.files[0]));
        setClickImageChange(true);
        setUploadImage({
          fileName: event.target.files[0].name.split(".")[0],
          fileType: event.target.files[0].type,
          key: `${event.target.files[0].name.split(".")[0]}-${uniqueId}.${
            event.target.files[0].name.split(".")[1]
          }`,
          file: event.target.files[0],
        });
      }
    } else {
      // Reset the input field value to clear the selected file
      event.target.value = null;
    }
  };

  const handlePdfDisplay = (event: any) => {
    event.preventDefault();

    if (ValidateFileType(event, "PDF") && validateFileSize(event)) {
      if (event.target.files[0]) {
        setSubmitting(true);
        setPdfSrc(URL.createObjectURL(event.target.files[0]));
        setClickPdfChange(true);
        setUploadPdf({
          fileName: event.target.files[0].name.split(".")[0],
          fileType: event.target.files[0].type,
          key: `${event.target.files[0].name.split(".")[0]}-${uniqueId}`,
          file: event.target.files[0],
        });
      }
    } else {
      // Reset the input field value to clear the selected file
      event.target.value = null;
    }
  };
  const handleEventInformationUpdate = async (
    values: Partial<EventInformation>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      let imageUrl;
      let pdfUrl;
      if (clickImageChange) {
        imageUrl = await handleFileUpload(
          uploadImage.file,
          uploadImage.key,
          uploadImage.fileType,
          thumb,
          thumbLarge
        );
      }
      if (clickPdfChange) {
        pdfUrl = await handleFileUpload(
          uploadPdf.file,
          uploadPdf.key,
          uploadPdf.fileType,
          thumb,
          false
        );
      }

      const input = {
        ...values,
      };

      if (!eventId) {
        input.coverImageUrl = imageUrl;
        pdfUrl
          ? input.relevantFiles?.push(pdfUrl)
          : (input.relevantFiles = null);
      } else {
        input.coverImageUrl = uploadImage?.file
          ? imageUrl
          : data?.coverImageUrl;
        input.relevantFiles = [];
        if (uploadPdf?.file) {
          input.relevantFiles.push(pdfUrl);
        } else if (
          data?.relevantFiles?.[0] !== undefined &&
          data.relevantFiles[0] !== null
        ) {
          input.relevantFiles.push(data.relevantFiles[0]);
        }
      }
      await handleUpdate({
        variables: { eventId: eventId, input: { ...input } },
      });

      refetch();
      setSubmitting(false);
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };

  const initialValues = {
    title: (eventId && data?.title) || "",
    mainLocation: (eventId && data?.mainLocation) || null,
    mainLink: (eventId && data?.mainLink) || null,
    isCompliance: eventId && data?.isCompliance === true ? "true" : "",
    bannerColor:
      (eventId && (data?.bannerColor === "EMPTY" ? "" : data?.bannerColor)) ||
      "",
    eventType: (eventId && data?.eventType) || "",
    pageType: (eventId && data?.pageType) || "",
    suitableFor: (eventId && data?.suitableFor) || [],
    cpdPoints: (eventId && data?.cpdPoints) || null,
    coverImageUrl: (eventId && data?.coverImageUrl) || "",
    relevantFiles: (eventId && data?.relevantFiles) || [],
    openAnytime: (eventId && data?.openAnytime) || false,

    sessions:
      eventId && data?.sessions?.length
        ? data?.sessions.map((session) => {
            const formattedTimes = formatTime(session);

            // Return a new session object with the formatted start time
            return {
              ...session,
              startTime: formattedTimes?.formattedStartTime,
              endTime: formattedTimes?.formattedEndTime,
            };
          })
        : [
            {
              startDate: "",
              endDate: "",
              startTime: "",
              endTime: "",
              modality: "",
              link: "",
            },
          ],
  };
  if (loading || getEventLoading) {
    return <Spinner />;
  }

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={submitting ? false : true}
        initialValues={initialValues}
        validationSchema={eventValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          /* make data sync with update userDTO */

          const checkDetails =
            eventId &&
            (data?.furtherDetails === null || data?.pricing === null);

          const input = {
            ...values,
            isCompliance: values.isCompliance === "true" ? true : false,
            openAnytime: values.openAnytime ? values.openAnytime : false,
            bannerColor:
              values?.bannerColor === "" ? "EMPTY" : values?.bannerColor,
            mainLink: values.openAnytime === true ? values?.mainLink : null,
            sessions:
              values.openAnytime === true
                ? []
                : values.sessions.map<SessionOutput>(
                    (session: SessionOutput) => ({
                      ...session,
                      startTime: dayjs(session.startTime).format("hh:mm A"),
                      endTime: dayjs(session.endTime).format("hh:mm A"),
                      modality: session.modality ? session.modality : null,
                    })
                  ),
          };
          setSubmitting(true);
          handleEventInformationUpdate(input, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          isSubmitting,
          handleChange,
          getFieldProps,
          setFieldValue,

          /* and other goodies */
        }) => (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {eventId
                  ? "Update Event Information"
                  : "Create Event Information"}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "15px" }} container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Enter event name"
                  label="Event Name"
                  {...getFieldProps("title")}
                />
                {touched.title && errors.title && (
                  <FormHelperText error id="title-error">
                    {errors.title}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="isCompliance"
                  label="Parent Course"
                  select
                  fullWidth
                  sx={{ width: "100%" }}
                  // name="isCompliance"
                  {...getFieldProps("isCompliance")}
                  onChange={(e: any) => {
                    setFieldValue("isCompliance", e.target.value);
                    if (e.target.value === "") {
                      setFieldValue("bannerColor", "");
                    }
                  }}
                >
                  {isComplianceArray.map((option) => (
                    <MenuItem
                      sx={{
                        padding: option.label === "" ? "15px" : undefined,
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                  {/* { setFieldValue('bannerColor', '')} */}
                </TextField>
                {touched.isCompliance && errors.isCompliance && (
                  <FormHelperText error id="isCompliance-error">
                    {errors.isCompliance}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="bannerColor"
                  label="Banner Colour"
                  select
                  disabled={values?.isCompliance === "" ? true : false}
                  fullWidth
                  sx={{ width: "100%" }}
                  {...getFieldProps("bannerColor")}
                >
                  {BannerColors.map((option) => (
                    <MenuItem
                      sx={{
                        padding: option.label === "" ? "15px" : undefined,
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {touched.bannerColor && errors.bannerColor && (
                  <FormHelperText error id="bannerColor-error">
                    {errors.bannerColor}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="eventType"
                  label="Event Type"
                  select
                  fullWidth
                  sx={{ width: "100%" }}
                  {...getFieldProps("eventType")}
                >
                  {EventType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                {touched.eventType && errors.eventType && (
                  <FormHelperText error id="eventType-error">
                    {errors.eventType}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="PageType"
                  label="Page Type"
                  select
                  fullWidth
                  sx={{ width: "100%" }}
                  {...getFieldProps("pageType")}
                >
                  {PageTypeArray.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {touched.pageType && errors.pageType && (
                  <FormHelperText error id="pageType-error">
                    {errors.pageType}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  // value={values.suitableFor ? values.suitableFor : []}
                  {...getFieldProps("suitableFor")}
                  onChange={(event, value) => {
                    setFieldValue("suitableFor", value);
                  }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Suitable For"
                      placeholder="Enter Suitable Fields"
                    />
                  )}
                />

                {touched.suitableFor && errors.suitableFor && (
                  <FormHelperText error id="suitableFor-error">
                    {errors.suitableFor}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Enter CPD Points"
                  label="CPD Points"
                  InputProps={{
                    inputProps: { min: 0, step: 0.1 },
                  }}
                  {...getFieldProps("cpdPoints")}
                />
                {touched.cpdPoints && errors.cpdPoints && (
                  <FormHelperText error id="cpdPoints-error">
                    {errors.cpdPoints}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Enter location"
                  label="Location"
                  {...getFieldProps("mainLocation")}
                />
                {touched.mainLocation && errors.mainLocation && (
                  <FormHelperText error id="mainLocation-error">
                    {errors.mainLocation}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    {!eventId && (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          variant="outlined"
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            marginTop: "1.5rem",
                            width: "100%",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <UploadTwoToneIcon />
                              </InputAdornment>
                            ),

                            style: {
                              padding: "0.4rem",
                              cursor: "pointer",
                            },
                          }}
                          id="containedButtonFile"
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleImageDisplay(e)}
                          inputProps={{
                            accept: ".jpeg, .png, .jpg",
                          }}
                        />
                      </Stack>
                    )}
                    {eventId && (
                      <Grid>
                        <Grid item xs={12} sm={6} sx={{ marginTop: "1.5rem" }}>
                          <Avatar
                            alt="image"
                            src={imageSrc || data?.coverImageUrl}
                            sx={{
                              height: 80,
                              width: 80,
                              marginBottom: "1.25rem",
                            }}
                          />
                        </Grid>
                        <Grid item sm zeroMinWidth sx={{ marginTop: "0.5rem" }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  startIcon={<UploadTwoToneIcon />}
                                  sx={{
                                    color: "black",
                                    backgroundColor: "white",
                                  }}
                                  onClick={handleButtonClick}
                                >
                                  <input
                                    accept=".jpeg, .png, .jpg"
                                    ref={hiddenImageInput}
                                    style={{
                                      display: "none",
                                      opacity: 0,
                                      position: "absolute",
                                      zIndex: 1,
                                      padding: 0.5,
                                      cursor: "pointer",
                                    }}
                                    id="containedButtonFile"
                                    multiple
                                    type="file"
                                    onChange={(e) => handleImageDisplay(e)}
                                  />
                                  {lang.IMAGE.CHANGE_IMAGE}
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Typography sx={{ marginTop: "1rem" }} variant="caption">
                      Cover Image
                    </Typography>
                  </Grid>

                  <Grid marginTop={"10px"} item xs={6}>
                    <Typography variant="caption">
                      <ErrorTwoToneIcon
                        sx={{
                          height: 16,
                          width: 16,
                          mr: 1,
                          verticalAlign: "text-bottom",
                        }}
                      />
                      Image size Limit should be 5 Mb Max.
                    </Typography>
                  </Grid>
                  <Grid marginTop={"10px"} item xs={6}>
                    <Typography variant="caption">
                      <ErrorTwoToneIcon
                        sx={{
                          height: 16,
                          width: 16,
                          mr: 1,
                          verticalAlign: "text-bottom",
                        }}
                      />
                      Recommended size 25 * 50
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid item xs={12}>
                    {!eventId && (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          variant="outlined"
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            marginTop: "1.5rem",
                            width: "100%",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <UploadTwoToneIcon />
                              </InputAdornment>
                            ),

                            style: {
                              padding: "0.4rem",
                              cursor: "pointer",
                            },
                          }}
                          id="containedButtonFile"
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handlePdfDisplay(e)}
                          inputProps={{
                            accept: ".pdf",
                          }}
                        />
                      </Stack>
                    )}
                    {eventId && (
                      <Grid>
                        <Grid item xs={12} sm={6} sx={{ marginTop: "1.5rem" }}>
                          <IconButton
                            onClick={() => {
                              data?.relevantFiles?.[0] &&
                                window.open(
                                  `${data?.relevantFiles?.[0]}`,
                                  "_blank"
                                );
                            }}
                          >
                            <PictureAsPdfTwoToneIcon
                              sx={{ height: 80, width: 80 }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item sm zeroMinWidth sx={{ marginTop: "0.5rem" }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  startIcon={<UploadTwoToneIcon />}
                                  sx={{
                                    color: "black",
                                    backgroundColor: "white",
                                  }}
                                  onClick={handleButtonClickPdf}
                                >
                                  <input
                                    accept=".pdf"
                                    ref={hiddenPdfInput}
                                    style={{
                                      display: "none",
                                      opacity: 0,
                                      position: "absolute",
                                      zIndex: 1,
                                      padding: 0.5,
                                      cursor: "pointer",
                                    }}
                                    id="containedButtonFile"
                                    multiple
                                    type="file"
                                    onChange={(e) => handlePdfDisplay(e)}
                                  />
                                  {uploadPdf.fileName || lang.IMAGE.CHANGE_PDF}
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Typography sx={{ marginTop: "1rem" }} variant="caption">
                      Relevant File
                    </Typography>
                  </Grid>

                  <Grid marginTop={"10px"} item xs={6}>
                    <Typography variant="caption">
                      <ErrorTwoToneIcon
                        sx={{
                          height: 16,
                          width: 16,
                          mr: 1,
                          verticalAlign: "text-bottom",
                        }}
                      />
                      Pdf size Limit should be 5 Mb Max.
                    </Typography>
                  </Grid>
                  <Grid marginTop={"10px"} item xs={6}>
                    <Typography variant="caption">
                      <ErrorTwoToneIcon
                        sx={{
                          height: 16,
                          width: 16,
                          mr: 1,
                          verticalAlign: "text-bottom",
                        }}
                      />
                      Recommended size 25 * 50
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} marginTop={"20px"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.openAnytime === true ? true : false}
                    {...getFieldProps(`openAnytime`)}
                    onChange={(e) => {
                      setFieldValue(`openAnytime`, e.target.checked);
                    }}
                    name="checked"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle1">Open Anytime</Typography>
                }
              />
              <FormHelperText error id="openAnytime-error">
                <ErrorMessage name={`openAnytime`} />
              </FormHelperText>
            </Grid>
            {values?.openAnytime && (
              <Grid item xs={12}>
                <TextField
                  // value={values?.mainLink}
                  placeholder="https://www.website.com"
                  label="Link"
                  {...getFieldProps("mainLink")}
                  fullWidth
                  margin="normal"
                />
                {touched.mainLink && errors.mainLink && (
                  <FormHelperText error id="mainLink-error">
                    {errors.mainLink}
                  </FormHelperText>
                )}
              </Grid>
            )}
            {/**session start */}
            {!values.openAnytime && (
              <FieldArray
                name="sessions"
                render={(arrayHelpers) => (
                  <>
                    {values.sessions.map((session, index) => (
                      <div key={index}>
                        <Grid sx={{ marginTop: "25px" }} item xs={12}>
                          <Typography variant="h4">{`Session ${
                            index + 1
                          }`}</Typography>
                        </Grid>

                        <Grid sx={{ marginTop: "5px" }} container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                format="dd/MM/yyyy"
                                value={
                                  values?.sessions[index].startDate
                                    ? new Date(values.sessions[index].startDate)
                                    : null
                                }
                                label="Start Date"
                                minDate={
                                  eventId
                                    ? new Date(
                                        new Date(
                                          data?.booking?.endDate || ""
                                        ).getTime() +
                                          24 * 60 * 60 * 1000
                                      )
                                    : null
                                }
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `sessions[${index}].startDate`,
                                    newValue
                                      ? formatDate(dayjs(newValue))
                                      : null
                                  );
                                  if (newValue === null || newValue) {
                                    setFieldValue(
                                      `sessions[${index}].endDate`,
                                      null
                                    );
                                  }
                                }}
                              />
                            </LocalizationProvider>
                            <FormHelperText error id="title-error">
                              <ErrorMessage
                                name={`sessions.${index}.startDate`}
                              />
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                value={
                                  values?.sessions[index].endDate
                                    ? new Date(values.sessions[index].endDate)
                                    : null
                                }
                                format="dd/MM/yyyy"
                                label="End Date"
                                disabled={
                                  values?.sessions[index].startDate
                                    ? false
                                    : true
                                }
                                minDate={
                                  new Date(values?.sessions[index]?.startDate)
                                }
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `sessions[${index}].endDate`,
                                    newValue
                                      ? formatDate(dayjs(newValue))
                                      : null
                                  );

                                  if (newValue === null) {
                                    setFieldValue(
                                      `sessions[${index}].startDate`,
                                      null
                                    );
                                  }
                                }}
                              />
                            </LocalizationProvider>
                            <FormHelperText error id="title-error">
                              <ErrorMessage
                                name={`sessions[${index}].endDate`}
                              />
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label="Start Time"
                                sx={{ width: "100%" }}
                                ampm={true}
                                timeSteps={{ minutes: 15 }}
                                value={
                                  values?.sessions[index]?.startTime
                                    ? dayjs(values.sessions[index].startTime)
                                    : null
                                }
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `sessions[${index}].startTime`,
                                    newValue ? dayjs(newValue).toJSON() : null
                                  );
                                }}
                              />
                            </LocalizationProvider>
                            <FormHelperText error id="title-error">
                              <ErrorMessage
                                name={`sessions[${index}].startTime`}
                              />
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label="End Time"
                                sx={{ width: "100%" }}
                                ampm={true}
                                timeSteps={{ minutes: 15 }}
                                value={
                                  values?.sessions[index]?.endTime
                                    ? dayjs(values.sessions[index].endTime)
                                    : null
                                }
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `sessions[${index}].endTime`,
                                    newValue ? dayjs(newValue).toJSON() : null
                                  );
                                }}
                              />
                            </LocalizationProvider>
                            <FormHelperText error id="title-error">
                              <ErrorMessage
                                name={`sessions[${index}].endTime`}
                              />
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel>Event Modality</InputLabel>
                            <RadioGroup
                              row
                              aria-labelledby={`Modality-group-label`}
                              name={`sessions[${index}].modality`}
                              onBlur={handleBlur}
                              value={values.sessions[index].modality}
                              onChange={(event) => {
                                const newValue = event.target.value;
                                setFieldValue(
                                  `sessions[${index}].modality`,
                                  newValue
                                );
                              }}
                            >
                              {Modality.map(({ label, value }, innerIndex) => (
                                <FormControlLabel
                                  key={innerIndex}
                                  control={<Radio />}
                                  label={label}
                                  value={value}
                                />
                              ))}
                            </RadioGroup>
                            <FormHelperText error id="title-error">
                              <ErrorMessage
                                name={`sessions[${index}].modality`}
                              />
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              margin="normal"
                              placeholder="https://www.website.com"
                              {...getFieldProps(`sessions[${index}].link`)}
                              label="Link"
                            />
                            <FormHelperText error id="link-error">
                              <ErrorMessage name={`sessions[${index}].link`} />
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={9.7}></Grid>
                          {index > 0 && (
                            <Grid item xs={2.3}>
                              <IconButton
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                sx={{
                                  color: "red",
                                  marginBottom: "3rem",
                                }}
                              >
                                <CancelTwoToneIcon />
                                <Typography>Delete Session</Typography>
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    ))}
                    <Grid item xs={12}>
                      <IconButton
                        type="button"
                        sx={{ color: "#004C8F" }}
                        onClick={() =>
                          arrayHelpers.push({
                            startDate: "",
                            endDate: "",
                            startTime: "",
                            endTime: "",
                            modality: "",
                            link: "",
                          })
                        }
                      >
                        <AddCircleTwoToneIcon />
                        <Typography>Add Session</Typography>
                      </IconButton>
                    </Grid>
                  </>
                )}
              />
            )}
            {/**session end */}

            <Grid container justifyContent="flex-end">
              <Stack
                direction="row"
                spacing={2}
                alignItems="right"
                sx={{ mt: "1rem" }}
              >
                {eventInformationDetails?.status !== "INACTIVE" && (
                  <>
                    {eventInformationDetails?.openAnytime === true ? (
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        onClick={(e) => {
                          handleCancelEvent(eventId, "INACTIVE");
                        }}
                      >
                        Cancel Event
                      </Button>
                    ) : (
                      eventInformationDetails?.sessions[0]?.startDate &&
                      new Date(
                        eventInformationDetails?.sessions[0]?.startDate
                      ) >= currentDate && (
                        <Button
                          disabled={isSubmitting}
                          variant="outlined"
                          onClick={(e) => {
                            handleCancelEvent(eventId, "INACTIVE");
                          }}
                        >
                          Cancel Event
                        </Button>
                      )
                    )}
                  </>
                )}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ color: "white" }}
                >
                  {isSubmitting ? "Please wait..." : "Save Changes"}
                </Button>
              </Stack>
            </Grid>
          </form>
        )}
      </Formik>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default EventDataInformation;
