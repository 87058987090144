import { uploadImageDirectly } from "common/imageUploadDirect";

const FilePicker = (
  cb: (value: string, meta?: Record<string, any> | undefined) => void,
  value: string,
  meta: Record<string, any>
) => {
  var input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");

  if (input.files) {
    input.onchange = async function () {
      var file = input.files?.[0] as File;

      var reader = new FileReader();

      const data = await uploadImageDirectly(file);
      if (data) {
        cb(data, { title: file.name });
      }

      reader.onload = function () {};
      reader.readAsDataURL(file);
    };
    input.click();
  }
};

export default FilePicker;
